<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <Editor api-key="4q91812x421qc6po6evarups2m6zj0hj92uulv0nvikgl784" :init="TinyMCE" v-model="content" />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  mixins: [settingMixin],
  components: {
    Editor
  },
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    content: {
      get() {
        return this.elementProps.content
      },
      set(value) {
        this.updateContent(value)
      },
    },
  },
  data () {
    return {
      SettingTitle: '內文',
      TinyMCE: TinyMCESetting
    }
  },
  methods: {
    updateContent(content) {
      this.elementPropsSetter({ content })
    }
  }
}
</script>
