<template>
  <div>
    <CInput :label="'ID'" size="sm" v-model="id" horizontal />
    <CInput :label="'Class'" size="sm" v-model="classes" horizontal />
    <CInput :label="'區塊標題'" size="sm" v-model="title" horizontal />
    <CInput :label="'區塊次標題'" size="sm" v-model="subTitle" horizontal />
    <CSelect horizontal :label="'展示型態'" :options="StyleOptions" size="sm" v-model="style" :value.sync="style" :placeholder="$t('Global.PleaseSelect')"/>
    <CSelect horizontal :label="$t('Post/Detail.Categories')" :options="CategoryList" size="sm" v-model="filter.Categories" :value.sync="filter.Categories" :placeholder="$t('Global.PleaseSelect')"/>
    <CRow form class="form-group">
      <CCol tag="label" sm="3" class="col-form-label">
        {{ $t('Post/Detail.Tags') }}
      </CCol>
      <CCol sm="9">
        <multiselect
          class="mutiselect---small"
          v-model="filter.Tags_IN"
          :tag-placeholder="$t('Global.EnterToAdd')"
          :placeholder="$t('Global.PleaseKeyIn')"
          :deselectLabel="$t('Global.DeselectLabel')"
          :selectedLabel="$t('Global.SelectedLabel')"
          :options="filter.Tags_IN"
          :multiple="true"
          :taggable="true"
          @tag="AddTag">
          <template #noOptions>
            {{ $t('Global.NoOptions') }}
          </template>
        </multiselect>
      </CCol>
    </CRow>
    <CSelect horizontal :label="$t('Post/Detail.Sort')" :options="SortType" size="sm" v-model="filter.Order" :value.sync="filter.Order" :placeholder="$t('Global.PleaseSelect')"/>
    <CInput :label="'顯示個數'" size="sm" v-model="filter.PerPage" horizontal type="number" />
  </div>
</template>

<script>
import { settingMixin } from '@v-craft/core'

export default {
  mixins: [settingMixin],
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  computed: {
    id: {
      get() {
        return this.elementProps.id
      },
      set(id) {
        this.updateContent({id})
      }
    },
    classes: {
      get() {
        return this.elementProps.classes
      },
      set(classes) {
        this.updateContent({classes})
      }
    },
    title: {
      get() {
        return this.elementProps.title
      },
      set(title) {
        this.updateContent({title})
      }
    },
    subTitle: {
      get() {
        return this.elementProps.subTitle
      },
      set(subTitle) {
        this.updateContent({subTitle})
      }
    },
    list: {
      get() {
        return this.elementProps.list
      },
      set(list) {
        this.updateContent({list})
      }
    },
    filter: {
      get() {
        return this.elementProps.filter
      },
      set(filter) {
        this.updateContent({filter})
      }
    },
    style: {
      get() {
        return this.elementProps.style
      },
      set(style) {
        this.updateContent({style})
      }
    },
    SortType () {
      return Object.keys(this.$t('Global.SortType')).map((order) => {
        return {
          label: this.$t('Global.SortType.' + order),
          value: order
        }
      })
    }
  },
  data() {
    return {
      SettingTitle: '文章',
      CategoryList: [],
      StyleOptions: [
        {
          label: '圖文網格',
          value: 'thumbnail'
        },
        {
          label: '圖文輪播',
          value: 'carousel'
        }
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    return Promise.all([
      this.GetCategories()
    ]).then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    updateContent(prop) {
      Object.keys(prop).forEach(key => {
        if (typeof prop[key] === 'string' || typeof prop[key] === 'object' || typeof prop[key] === 'number') {
          this.elementPropsSetter(prop)
        }
      })
    },
    AddTag (newTag) {
      this.filter.Tags_IN.push(newTag)
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return { label: category.Name, value: category.Slug }
        })
        this.CategoryList.push({ label: '不篩選分類', value: '' })
      }).catch((err) => {
        throw err
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.col-form-label {
  font-size: 12px;
}
</style>
