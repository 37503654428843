<template>
  <Editor id="EditorInstance" ref="EditorInstance" component="div" class="c-app" :resolverMap="resolverMap">
    <CHeader style="z-index: 1032">
      <CHeaderNav class="d-md-down-none mr-auto">
        <CHeaderNavItem class="px-3">
          <CHeaderNavLink to="/">
            <i class="fas fa-angle-left mr-2"/> 回到管理後台
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav class="d-md-down-none ml-auto">
        <template v-if="$store.state.user.permission.MutipleLocale">
          <CHeaderNavItem class="px-1">
            <CButton :color="(NowChooseLanguage === 'default' ? 'info' : 'light')" @click="ChangeLocale('default')">
              {{ $t('Locale.Default') }}
            </CButton>
          </CHeaderNavItem>
          <CHeaderNavItem v-for="value in CurrentLocales" :key="value" class="px-1">
            <CButton :color="(NowChooseLanguage === value ? 'info' : 'light')"  @click="ChangeLocale(value)">
              <img :src="`https://storage.ksong.tw/Locales/${value}.png`" class="mr-2" :alt="value" style="width:16px;height:16px">{{ $t('Locale.' + value) }}
            </CButton>
          </CHeaderNavItem>
        </template>
        <CHeaderNavItem class="px-1 pr-3">
          <CButton color="success" @click="Save()">
            {{ $t('Global.Save') }}
          </CButton>
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
    <CSidebar :show="true" colorScheme="light" :minimize="Minimize" class="shadow">
      <div style="margin-top: 60px;overflow:auto" class="p-2 h-100">
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="far fa-square mr-2" />
            <span v-if="!Minimize">容器</span>
          </template>
          <template v-slot:blueprint>
            <Canvas component="Container"/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-layer-group mr-2" />
            <span v-if="!Minimize">圖層</span>
          </template>
          <template v-slot:blueprint>
            <Canvas component="Div" />
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-paragraph mr-2" />
            <span v-if="!Minimize">段落</span>
          </template>
          <template v-slot:blueprint>
            <Paragraph/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-file-alt mr-2" />
            <span v-if="!Minimize">內容</span>
          </template>
          <template v-slot:blueprint>
            <Content/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-square mr-2" />
            <span v-if="!Minimize">按鈕</span>
          </template>
          <template v-slot:blueprint>
            <Button/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-grip-horizontal mr-2" />
            <span v-if="!Minimize">網格區塊</span>
          </template>
          <template v-slot:blueprint>
            <Canvas component="Row"/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-th-large mr-2" />
            <span v-if="!Minimize">網格</span>
          </template>
          <template v-slot:blueprint>
            <Canvas component="Grid"/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-link mr-2" />
            <span v-if="!Minimize">連結</span>
          </template>
          <template v-slot:blueprint>
            <Canvas component="Link"/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-image mr-2" />
            <span v-if="!Minimize">圖片</span>
          </template>
          <template v-slot:blueprint>
            <ImageInsert/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fas fa-images mr-2" />
            <span v-if="!Minimize">輪播</span>
          </template>
          <template v-slot:blueprint>
            <Banner/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fab fa-youtube mr-2" />
            <span v-if="!Minimize">Youtube影片</span>
          </template>
          <template v-slot:blueprint>
            <YoutubeEmbed/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <i class="fab fa-html5 mr-2" />
            <span v-if="!Minimize">HTML區塊</span>
          </template>
          <template v-slot:blueprint>
            <HTML/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <CIcon name="cil-star" class="c-icon-custom-size mr-2" />
            <span v-if="!Minimize">商品</span>
          </template>
          <template v-slot:blueprint>
            <Products/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <CIcon name="cil-layers" class="c-icon-custom-size mr-2" />
            <span v-if="!Minimize">商品分類</span>
          </template>
          <template v-slot:blueprint>
            <ProductCategory/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <CIcon name="cil-pencil" class="c-icon-custom-size mr-2" />
            <span v-if="!Minimize">文章</span>
          </template>
          <template v-slot:blueprint>
            <Posts/>
          </template>
        </Blueprint>
        <Blueprint component="CButton" type="button" color="light" block>
          <template #default>
            <CIcon name="cil-notes" class="c-icon-custom-size mr-2" />
            <span v-if="!Minimize">文章分類</span>
          </template>
          <template v-slot:blueprint>
            <PostCategory/>
          </template>
        </Blueprint>
      </div>
      <CSidebarMinimizer
        class="d-md-down-none"
        @click.native="Minimize = !Minimize"
      />
    </CSidebar>
    <CWrapper :fluid="Toggle">
      <div class="c-body">
        <main class="c-main mt-5">
          <CContainer>
            <CAlert color="info" :close-button="true">
              <CIcon name="cil-bell"/> 拖曳左方組件至編輯處即可將組件新增至頁面中。
            </CAlert>
            <CAlert color="warning" :close-button="true">
              <CIcon name="cil-bell"/> 編輯模式可能會因專案加載的函式庫不同而與實際畫面渲染不同，建議您儲存後以實際畫面對照為主。
            </CAlert>
            <CCard>
              <CCardHeader class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0 font-weight-bold">
                  {{ Data.Name }}
                </h5>
              </CCardHeader>
              <CCardBody id="PageBuilder">
                <Frame ref="Frame" component="div">
                  <Canvas component="Container" />
                  <hr>
                </Frame>
              </CCardBody>
            </CCard>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab v-if="!FixSlug.includes($route.params.slug)" :title="$t('Post/Detail.Info')" :active="!FixSlug.includes(this.$route.params.slug)">
                <CRow>
                  <CCol md="8">
                    <CInput
                      :label="$t('Post/Detail.Slug')"
                      v-model="Data.Slug"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.Name')"
                      v-model="Data.Name"
                      horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Post/Detail.SEO')" :active="FixSlug.includes($route.params.slug)">
                <CRow>
                  <CCol md="8">
                    <CInput
                      :label="$t('Post/Detail.SEO/Title')"
                      v-model="Data.SEO.Title"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.SEO/Keyword')"
                      v-model="Data.SEO.Keywords"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.SEO/Description')"
                      v-model="Data.SEO.Description"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.SEO/OG:Title')"
                      v-model="Data.SEO.OGTitle"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.SEO/OG:Description')"
                      v-model="Data.SEO.OGDescription"
                      horizontal
                    />
                    <CInput
                      :label="$t('Post/Detail.SEO/OG:Image')"
                      v-model="Data.SEO.OGImage"
                      horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CContainer>
        </main>
      </div>
    </CWrapper>
    <SettingPanel :Toggle.sync="Toggle" />
  </Editor>
</template>

<route>
{
"meta": {
"label": "佈景構建"
}
}
</route>

<script>
import {Canvas, Editor, Frame, Blueprint} from '@v-craft/core'
import Container from '@/components/elements/Container.vue'
import Div from '@/components/elements/Div.vue'
import Paragraph from '@/components/elements/Paragraph.vue'
import Button from '@/components/elements/Button.vue'
import Products from '@/components/elements/Products.vue'
import ProductCategory from '@/components/elements/ProductCategory.vue'
import Posts from '@/components/elements/Posts.vue'
import PostCategory from '@/components/elements/PostCategory.vue'
import ImageInsert from '@/components/elements/ImageInsert.vue'
import Banner from '@/components/elements/Banner.vue'
import HTML from '@/components/elements/HTML.vue'
import Content from '@/components/elements/Content.vue'
import Row from '@/components/elements/Row.vue'
import Grid from '@/components/elements/Grid.vue'
import Link from '@/components/elements/Link.vue'
import YoutubeEmbed from '@/components/elements/YoutubeEmbed.vue'
import SettingPanel from '@/components/SettingPanel.vue'
// import Export from '@/components/Export.vue'

export default {
  name: "ThemeBuildingSlug",
  components: {
    Canvas, Editor, Frame, Paragraph, SettingPanel, Blueprint, HTML, ImageInsert, Banner, Products,
    ProductCategory,
    Posts,
    PostCategory,
    Content, Button, YoutubeEmbed
  },
  data() {
    return {
      resolverMap: {
        Canvas, Container, Paragraph, HTML, Row, Grid, ImageInsert, Banner, Products,
        ProductCategory,
        Posts,
        PostCategory,
        Content, Button, Link, Div, YoutubeEmbed
      },
      FixSlug: ['home', 'about', 'private_policy', 'terms', 'member_rules'],
      Minimize: false,
      NowChooseLanguage: 'default',
      Action: 'Edit',
      Loading: false,
      Toggle: false,
      ShowSlug: false,
      Data: {
        Slug: '',
        Taxonomy: 'ThemesBuild',
        Variable: {},
        SEO: {}
      }
    }
  },
  computed: {
    CurrentLocales () {
      return this.$store.state.user.permission.Locales.filter(item => item !== this.$store.state.user.permission.DefaultLocale)
    }
  },
  created() {
    let DataSchema
    this.Action = (this.$route.name === 'pag-builder-add' ? 'Add' : 'Edit')
    try {
      DataSchema = require(`@/schema/themesBuild/${this.$route.params.slug}`)
    } catch (error) {
      this.ShowSlug = true
      DataSchema = require(`@/schema/themesBuild/default`)
    }
    this.$set(this.$data, 'Data', Object.assign(this.$data.Data, DataSchema))
    const PermissionSession = JSON.parse(localStorage.getItem('Permission')) || {}
    document.title = this.Data.Name + ' - ' + (PermissionSession.ProjectTitle ? PermissionSession.ProjectTitle : process.env.VUE_APP_PLATFORM_TITLE)
  },
  mounted() {
    // console.log(this.$refs.EditorInstance.editor)
    const Execute = []
    this.$Progress.start()
    if (this.Action === 'Edit') {
      Execute.push(
        this.Load()
      )
    }
    return Promise.all(Execute).then(() => {
      if (this.$store.state.user.permission.MutipleLocale) {
        this.NowChooseLanguage = this.$route.params.slug.split('_')[1] || 'default'
      }
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Load () {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/detail',
          Data: {
            Taxonomy: 'ThemesBuild',
            Slug: this.$route.params.slug,
            Manage: true
          }
        }
      }).then(({data}) => {
        this.Loading = false
        // 有資料則改為編輯動作，沒資料則為新增動作
        if (data.Data !== null) {
          this.Action = 'Edit'
          this.Data = data.Data
          this.$refs.EditorInstance.editor.import(data.Data.Variable.Template)
        } else {
          this.Data.Slug = this.$route.params.slug || ''
          this.Action = 'Add'
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    ChangeLocale (locale) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Info'),
        text: '您尚未儲存，建議您切換語系前先儲存變更的項目。',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Save'),
        cancelButtonText: '直接切換',
      }).then((result) => {
        if (result.isConfirmed) {
          this.Save().then(() => {
            window.location.href = `https://${window.location.hostname}/system/themesBuild/home${locale !== 'default' ? '_' + locale : ''}`
          })
        } else {
          window.location.href = `https://${window.location.hostname}/system/themesBuild/home${locale !== 'default' ? '_' + locale : ''}`
          // window.location.href = `http://${window.location.hostname}:8080/system/themesBuild/home${locale !== 'default' ? '_' + locale : ''}`
        }
      })
    },
    Save () {
      this.Data.Variable.Template = this.$refs.EditorInstance.editor.export()
      const SendData = {}
      this.Loading = true
      if (this.Action === 'Edit') {
        SendData.URL = '/content/article/edit'
        SendData.Data = {
          Taxonomy: 'ThemesBuild',
          Slug: this.Data.Slug,
          UpdateData: {
            Slug: this.Data.Slug,
            SEO: this.Data.SEO,
            'Variable.Template': this.Data.Variable.Template
          }
        }
      } else {
        SendData.URL = '/content/article/add'
        SendData.Data = this.Data
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: SendData
      }).then(() => {
        this.Loading = false
        this.$store.dispatch('InnerRequest', {
          url: '/content/cleanCache',
          method: 'post',
          data: {
            taxonomy: 'ThemesBuild',
            slug: this.Data.Slug
          }
        })
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/SaveSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/SaveFail') + err,
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#PageBuilder {
  inset: 0 425px 0 0;
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(45deg, rgb(247, 247, 247) 25%, transparent 25%), linear-gradient(-45deg, rgb(247, 247, 247) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(247, 247, 247) 75%), linear-gradient(-45deg, transparent 75%, rgb(247, 247, 247) 75%);
  background-size: 20px 20px;
  background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
  transition: right 0.3s ease 0s;
  overflow: scroll;
}
.EmptyNotify {
  outline: 0;
  border-radius: 0;
}
</style>
