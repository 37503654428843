<template>
  <div v-c-tooltip="'圖層'" class="div">
    <slot>
      <CAlert color="secondary" :close-button="false" class="mb-0 EmptyNotify text-center">
        目前圖層是空的，請從左方拖曳組件以新增內容。
      </CAlert>
    </slot>
  </div>
</template>

<script>
import DivSetting from './DivSetting.vue';

export default {
  props: {
    id: String,
    classes: String
  },
  craft: {
    defaultProps: {
      id: '',
      classes: ''
    },
    settings: {
      DivSetting,
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  outline: 1px dashed gray;
}
</style>
